import { SheetDto, WorkbookDto } from '@gucci/api-interfaces';
import { INavigationObject, INavigationProperties } from '@astrato-internal/api-daedalus';
import { DEFAULT_SHEET_PROPERTIES } from '../../../properties/sheet-properties';
import WORKBOOK_THEME_SHOWCASE_WORKBOOK_DATA from './workbook-theme-showcase-workbook-data.json';

export const WORKBOOK_THEME_SHOWCASE_WORKBOOK: WorkbookDto = {
  ...WORKBOOK_THEME_SHOWCASE_WORKBOOK_DATA,
  navigation: {
    ...WORKBOOK_THEME_SHOWCASE_WORKBOOK_DATA?.navigation,
    properties: {
      ...(WORKBOOK_THEME_SHOWCASE_WORKBOOK_DATA?.navigation.properties as unknown as INavigationProperties),
      backgroundColor: null,
      itemBackgroundColor: null,
      activeItemBackgroundColor: null,
      size: 100,
      padding: 2,
      enabled: true,
    },
    objects: (WORKBOOK_THEME_SHOWCASE_WORKBOOK_DATA?.navigation.objects as unknown as INavigationObject[])?.map(
      (object: INavigationObject) => ({
        ...object,
        properties: {
          ...object.properties,
          url: null,
        },
      })
    ),
  },
  image: null,
  sheets:
    (WORKBOOK_THEME_SHOWCASE_WORKBOOK_DATA?.sheets as unknown as SheetDto[])?.map((sheet: SheetDto) => ({
      ...sheet,
      properties: {
        ...DEFAULT_SHEET_PROPERTIES,
        ...sheet.properties,
      },
    })) || ([{ name: 'this will be overridden' }] as unknown as SheetDto[]),
} as unknown as WorkbookDto;
