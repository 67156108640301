export enum EOperatorType {
  EQUAL = 'eq',
  EQUAL_CI = 'eq_ci',
  NOT_EQUAL = 'ne',
  CONTAINS = 'co',
  STARTS_WITH = 'sw',
  ENDS_WITH = 'ew',
  // present checks !null or !undefined
  PRESENT = 'pr',
  GREATER_EQUAL = 'ge',
  GREATER_THAN = 'gt',
  LESS_THAN = 'lt',
  LESS_EQUAL = 'le',
}
