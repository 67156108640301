import { AstratoFonts, AvailableFont, FontStyleType } from '@astrato-internal/api-daedalus';

const AVAILABLE_FONT_WEIGHTS: number[] = [400, 700];
const AVAILABLE_FONT_STYLES: FontStyleType[] = [FontStyleType.NORMAL, FontStyleType.ITALIC];

const AVAILABLE_FONT_FAMILIES: string[] = [
  'Arial',
  'Arial Black',
  'Tahoma',
  'Verdana',
  'Roboto',
  'Roboto Mono',
  'Roboto Condensed',
  'Roboto Slab',
  'Open Sans',
  'Lato',
  'Montserrat',
  'Ubuntu',
  'Rubik',
  'Source Sans Pro',
  'IBM Plex Sans',
  'IBM Plex Sans Condensed',
  'IBM Plex Serif',
  'IBM Plex Mono',
  'Noto Serif',
];

const getAvailableFonts = (fontsList: string[]): AvailableFont[] => {
  return fontsList.map((fontFamily: string) => ({
    fontFamily,
    fontWeights: AVAILABLE_FONT_WEIGHTS,
    fontStyles: AVAILABLE_FONT_STYLES,
  }));
};

export const ASTRATO_FONTS: AstratoFonts = {
  defaultFont: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontStyle: FontStyleType.NORMAL,
  },
  availableFonts: getAvailableFonts(AVAILABLE_FONT_FAMILIES),
};
