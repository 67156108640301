export enum SharedResourceVisibility {
  restricted = 'restricted',
  external = 'external',
}

export enum EmbedAllowLevels {
  any = 'any',
  onlyRestricted = 'onlyRestricted',
}

export enum EmbeddedObjectType {
  object = 'object',
  media = 'media',
}

export enum EmbedType {
  object = 'object',
  group = 'group',
  sheet = 'sheet',
  workbook = 'workbook',
}
