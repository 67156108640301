export enum ELicenseType {
  FREE_TIER = 'free_tier',
  CONSUMPTION = 'consumption',
  SEATS = 'seats',
  HYBRID = 'hybrid',
  VIZLIB_LIVE = 'vizlib_live',
}

export enum ELicenseAllocationHistoryType {
  SWITCH_FREE_TIER = 'switch_free_tier',
  SWITCH_CONSUMPTION = 'switch_consumption',
  SWITCH_SEATS = 'switch_seats',
  SWITCH_HYBRID = 'switch_hybrid',
  ALLOCATE_CREDITS = 'allocate_credits',
  ALLOCATE_SEATS = 'allocate_seats',
  ALLOCATE_CREDITS_AND_SEATS = 'allocate_credits_and_seats', // for hybrid mode
}
