import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { AuthConfigDto, SocialLoginConnectionDto } from '@gucci/shared-auth/types-auth';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private readonly authConfig$: ReplaySubject<AuthConfigDto> = new ReplaySubject(1);

  constructor(private readonly httpClient: HttpClient) {}

  captchaSiteKey$ = this.authConfig$.pipe(
    take(1),
    map((config) => config.captchaSiteKey)
  );

  captchaSkipCheck$ = this.authConfig$.pipe(
    take(1),
    map((config) => config.captchaSkipCheck)
  );

  public getConfig(): Observable<AuthConfigDto> {
    return this.authConfig$.pipe(take(1));
  }

  public getConnections(): Observable<SocialLoginConnectionDto[]> {
    return this.getConfig().pipe(map((config) => config.connections || []));
  }

  sync(): Observable<AuthConfigDto> {
    return this.httpClient
      .get<AuthConfigDto>(`${document.location.origin}/auth/proxy/config`, {
        headers: { 'Content-Type': 'application/json' },
      })
      .pipe(
        tap((config) => {
          config.connections = config.connections.map((con) => {
            if (!con.url.match('^https?://')) {
              return { ...con, url: `${document.location.origin}${con.url}` };
            }
            return con;
          });
          this.authConfig$.next(config);
        })
      );
  }
}
