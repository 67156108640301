import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { SharedModule } from './shared/shared.module';
import { AlreadyAuthenticatedGuard } from './shared/guards/already-authenticated.guard';

const routes: Routes = [
  /*
  {
    path: 'sign-in',
    loadChildren: () => import('./pages/sign-in/sign-in.module').then((m) => m.SignInModule),
    canLoad: [AlreadyAuthenticatedGuard],
  },
  */
  {
    path: 'feedback',
    loadChildren: () =>
      import('./pages/feedback-messages/feedback-messages.module').then((m) => m.FeedbackMessagesModule),
  },
  {
    path: 'redirect',
    loadChildren: () =>
      import('./pages/redirect-messages/redirect-messages.module').then((m) => m.RedirectMessagesModule),
  },
  {
    path: '',
    loadChildren: () => import('./pages/authenticate/authenticate.module').then((m) => m.AuthenticateModule),
    canLoad: [AlreadyAuthenticatedGuard],
  },
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
