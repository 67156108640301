export enum ObjectManualDataColumnDataType {
  NUMBER = 'number',
  STRING = 'string',
  DATE = 'date',
}

export interface IObjectManualDataColumn {
  name: string;
  dataType: ObjectManualDataColumnDataType;
}

export type IObjectManualDataValue = string | number | null;

export interface IObjectManualData {
  columns: IObjectManualDataColumn[];
  rows: IObjectManualDataValue[][];
}
