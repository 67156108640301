export enum MIME_TYPES {
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
  PNG = 'image/png',
  WEBP = 'image/webp',
  CSV = 'text/csv',
  ZIP = 'application/zip',
  ASTX = 'application/octet-stream',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

export enum EXTENSIONS {
  JPEG = 'jpeg',
  JPG = 'jpg',
  PNG = 'png',
  WEBP = 'webp',
  CSV = 'csv',
  ZIP = 'zip',
  ASTX = 'astx',
  XLSX = 'xlsx',
}
