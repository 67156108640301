export enum LogSeverity {
  VERBOSE = 'verbose',
  DEBUG = 'debug',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  CRITICAL = 'critical',
}

export enum GCPLogFieldSpecifier {
  TRACE = 'logging.googleapis.com/trace',
}

export enum GCPLogValueSpecifier {
  TYPE_ERROR = 'type.googleapis.com/google.devtools.clouderrorreporting.v1beta1.ReportedErrorEvent',
}
