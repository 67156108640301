export enum PdfPageFormatType {
  LETTER = 'Letter', // 8.5in x 11in
  LEGAL = 'Legal', // 8.5in x 14in
  TABLOID = 'Tabloid', // 11in x 17in
  LEDGER = 'Ledger', // 17in x 11in
  A0 = 'A0', // 33.1in x 46.8in
  A1 = 'A1', // 23.4in x 33.1in
  A2 = 'A2', // 16.54in x 23.4in
  A3 = 'A3', // 11.7in x 16.54in
  A4 = 'A4', // 8.27in x 11.7in
  A5 = 'A5', // 5.83in x 8.27in
  A6 = 'A6', // 4.13in x 5.83in
}
