import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigService } from '../services/config.service';

@Injectable()
export class AlreadyAuthenticatedGuard implements CanLoad, CanActivate {
  constructor(private readonly router: Router, private readonly config: ConfigService) {}

  // will prevent module loading as it's unnecessary
  canLoad(): Observable<boolean | UrlTree> {
    return this.validate();
  }

  // will prevent sub pages to load
  canActivate(): Observable<boolean | UrlTree> {
    return this.validate();
  }

  private validate() {
    return this.config.getConfig().pipe(
      map(({ isAuthenticated }) => {
        if (isAuthenticated) {
          history.replaceState(null, null, document.location.origin);
          window.location.href = document.location.origin;
          return false;
        }
        return true;
      })
    );
  }
}
