export enum FontStyleType {
  NORMAL = 'normal',
  ITALIC = 'italic',
}

export interface AvailableFont {
  fontFamily: string;
  fontWeights: number[];
  fontStyles: FontStyleType[];
}

export interface DefaultFont {
  fontFamily: string;
  fontWeight: number;
  fontStyle: FontStyleType;
}
export interface AstratoFonts {
  defaultFont: DefaultFont;
  availableFonts: AvailableFont[];
}
