import { Observable } from 'rxjs';

export interface IAstratoEvent<T = unknown> {
  type: string;
  payload?: T;
  objectId?: string;
  reply<S>(type: string, payload?: S, objectId?: string): IAstratoEvent<S>;
  getResponse<S>(): Observable<IAstratoEvent<S>>;
}

export enum AstratoEvents {
  SHOW_GLOBAL_NOTIFICATION = 'ASTRATO_EVENT_SHOW_GLOBAL_NOTIFICATION',
  OBJECT_INTERACTION = 'ASTRATO_EVENT_OBJECT_INTERACTION',
  OPEN_VARIABLES_PANEL = 'ASTRATO_OPEN_VARIABLES_PANEL',
  SHOW_COMPONENT_TOAST = 'ASTRATO_EVENT_SHOW_COMPONENT_TOAST',
  CHANGE_COMPONENT_STATE = 'ASTRATO_EVENT_CHANGE_COMPONENT_STATE',
  GETTING_DATA = 'ASTRATO_EVENT_GETTING_DATA',
  ENABLE_DIMENSION = 'ASTRATO_EVENT_ENABLE_DIMENSION',
  DISABLE_DIMENSION = 'ASTRATO_EVENT_DISABLE_DIMENSION',
  ENABLE_MEASURE = 'ASTRATO_EVENT_ENABLE_MEASURE',
  DISABLE_MEASURE = 'ASTRATO_EVENT_DISABLE_MEASURE,',
}

export interface ObjectInteraction {
  eventId: string;
  payload?: ObjectInteractionPayload;
}

export type ObjectInteractionType = string | number | boolean;

export type ObjectInteractionPayload = Record<string, ObjectInteractionType | Array<ObjectInteractionType>>;

export interface ShowGlobalNotification {
  type: 'success' | 'warn' | 'error' | 'info';
  message: string;
  infinite?: boolean;
  details?: string;
}

export interface ChangeComponentState {
  state: 'NORMAL' | 'ERROR';
  message?: string;
  icon?: string;
  reloadObject?: boolean;
}

export interface GettingData {
  status: 'IN_PROGRESS' | 'FINISHED' | 'CANCELED';
}
