import { INavigationObjectProperties, ISheetProperties } from '@gucci/api-interfaces';
import { LineStyle, SeparatorPosition } from '@gucci/enums';
import {
  FilterBarType,
  MediaSource,
  NavigationPosition,
  NavigationType,
  INavigationProperties,
} from '@astrato-internal/api-daedalus';

export const DEFAULT_NAVIGATION_PROPERTIES: INavigationProperties = {
  position: NavigationPosition.TOP,
  navigationType: NavigationType.DROPDOWN,
  activeItemBackgroundColor: null,
  activeItemTextColor: null,
  backgroundColor: null,
  dropdownBackgroundColor: null,
  horizontalMargin: 15,
  itemBackgroundColor: null,
  itemBorder: 2,
  itemBorderColor: null,
  itemBorderRadius: 6,
  itemTextColor: null,
  verticalMargin: 10,
  itemBorderLineStyle: LineStyle.Line,
};

export const DEFAULT_SHEET_PROPERTIES: ISheetProperties = {
  background: {
    color: undefined,
    activeColor: undefined,
  },
  border: {
    size: undefined,
    color: undefined,
    radius: undefined,
  },
  text: {
    color: undefined,
    activeColor: undefined,
  },
  margin: {
    vertical: 10,
    horizontal: 15,
  },
  padding: {
    horizontal: 16,
    vertical: 6,
  },
  separator: {
    enabled: false,
    color: '#424B5A',
    size: 1,
    length: 25,
    margin: 0,
    position: SeparatorPosition.BEFORE,
  },
  icon: {
    enabled: false,
    type: undefined,
  },
  filterBar: {
    type: FilterBarType.FilterBar,
  },
  aspectRatio: {
    enabled: false,
    preset: 'wide',
    ratioHeight: 9,
    ratioWidth: 16,
  },
};

export const DEFAULT_LOGO_PROPERTIES: INavigationObjectProperties = {
  url: '',
  width: 40,
  height: 42,
  enabled: true,
  size: 100,
  padding: 0,
  mediaItem: null,
  logoSource: MediaSource.URL,
};

export const DEFAULT_GRID_STATE = {
  isGridEnabled: true,
  gridRowsCount: 12,
  gridColumnsCount: 12,
  gridLineColor: '#F2F2F2',
};

export const DEFAULT_SNAPPING_STATE = {
  isSnappingEnabled: true,
  isMarginSnappingEnabled: true,
  isMiddleSnappingEnabled: true,
  marginSnap: 20,
  snapSensitivity: 10,
  snappingColor: '#F03352',
};

export const DEFAULT_ROOT_CONTAINER_PROPERTIES = {
  backgroundColor: null,
  item: {
    borderSize: 0,
    borderRadius: 0,
    padding: 0,
    borderColor: '#37474F',
    borderType: 'solid',
  },
};
