import { EXTENSION_TYPE_GROUPS, MIME_TYPE_GROUPS } from '../mime-type-groups';

export const ACCEPTED_IMAGE_TYPES = MIME_TYPE_GROUPS.IMAGES.map((mimeType) => mimeType.replace('image/', ''));
export const ACCEPTED_IMAGE_TYPES_STRING = EXTENSION_TYPE_GROUPS.IMAGES.join(', ').toUpperCase();

export const ACCEPTED_IMAGE_AND_DOCS_TYPES = [...MIME_TYPE_GROUPS.IMAGES, ...MIME_TYPE_GROUPS.DOCUMENTS].join(', ');
export const ACCEPTED_IMAGE_AND_DOCS_EXTENSIONS = [
  ...EXTENSION_TYPE_GROUPS.IMAGES,
  ...EXTENSION_TYPE_GROUPS.DOCUMENTS,
].join(', ');
