// DEVICE_SCALE_FACTOR could be exposed to report action blocks, when set to 2 it will generate twice the pixel count.
// Higher pixel count can be needed when report should be printed.
// We could also expose the device scale factor directly if customers request that level of details.
export const DEVICE_SCALE_FACTOR = 1;
export const MAX_DEVICE_SCALE_FACTOR = 4;

// PDF_MAX_RESOLUTION Is the max of either width or height, depending on aspect ratio.
// Could be exposed to report action blocks.
export const PDF_DEFAULT_RESOLUTION = 1700;
export const PDF_MIN_RESOLUTION = 740;
export const PDF_MAX_RESOLUTION = 2960;

export const TIMEOUT_REPORT = 180_000; // Default time to wait for full report
export const MINIMUM_TIMEOUT = 60_000; // Minimum time to wait for full report
export const MAXIMUM_TIMEOUT = 3_600_000; // Maximum time to wait for full report
