export enum ButtonClass {
  MAIN_1 = 'main-1',
  MAIN_2 = 'main-2',
  SECONDARY_1 = 'secondary-1',
  PRIMARY_1 = 'primary-1',
}

export enum ButtonState {
  DEFAULT = 'default',
  PROGRESS = 'progress',
  SUCCESS = 'success',
  ERROR = 'error',
}
