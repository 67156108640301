export enum NavigationPosition {
  LEFT = 'left',
  TOP = 'top',
}

export enum NavigationType {
  DROPDOWN,
  LAYERS,
  HAMBURGER, // deprecated?, left only for backward compatibility
  STANDARD, // deprecated?, left only for backward compatibility
  ICONS,
  NONE,
}

export enum NavigationObjectType {
  LOGO,
}

export interface INavigationProperties {
  position: NavigationPosition;
  navigationType: NavigationType;
  backgroundColor: string;
  dropdownBackgroundColor: string;
  itemTextColor: string;
  itemBackgroundColor: string;
  itemBorderEnabled?: boolean;
  itemBorder?: number;
  itemBorderRadius: number;
  itemBorderLineStyle?: string;
  itemBorderColor: string;
  itemsBorderMargin?: number;
  activeItemTextColor: string;
  activeItemBackgroundColor: string;
  verticalMargin: number;
  horizontalMargin: number;
}

export interface INavigation {
  id: string;
  properties: INavigationProperties;
  navigationObjects: INavigationObject[];
  workbookId: string;
  originalId?: string;
}

export interface INavigationObjectProperties {
  enabled: boolean;
  url?: string;
  width: number;
  height: number;
  size?: number;
  padding?: number;
  mediaItem?: {
    src: string;
    id: string;
  };
  logoSource: MediaSource;
}

export interface INavigationObject {
  id: string;
  type: NavigationObjectType;
  navigation?: INavigation;
  navigationId?: INavigation['id'];
  properties: INavigationObjectProperties;
  ownerId: string;
  // workbookId: string;
}

export enum MediaSource {
  URL = 'url',
  GALLERY = 'media-gallery',
}
