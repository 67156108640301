const API_CODE_RANGE = 10;

export const API_ERROR_CODES = new Proxy(
  {
    INVALID_CSRF: '001',
  },
  {
    get(target, prop) {
      return `${API_CODE_RANGE}${target[prop]}`;
    },
  }
);
