export const USER_INVITE_ROLES = {
  administrator: 'administrator',
  creator: 'creator',
  designer: 'designer',
  viewer: 'viewer',
} as const;
export type USER_INVITE_ROLES = (typeof USER_INVITE_ROLES)[keyof typeof USER_INVITE_ROLES];

export const USER_ROLES = {
  ...USER_INVITE_ROLES,
  owner: 'owner' as const,
};
export type USER_ROLES = (typeof USER_ROLES)[keyof typeof USER_ROLES];

export enum USER_ROLE_DISPLAY_NAMES {
  owner = 'Owner',
  administrator = 'Admin',
  creator = 'Creator',
  designer = 'Designer',
  viewer = 'Viewer',
}

export enum CREATION_ORIGIN_DISPLAY_NAMES {
  internal = 'Internal',
  scim = 'SCIM',
}

// only 1 owner per tenant
export const INVITE_AVAILABLE_USER_ROLES = [
  { label: USER_ROLE_DISPLAY_NAMES.administrator, value: USER_INVITE_ROLES.administrator },
  { label: USER_ROLE_DISPLAY_NAMES.creator, value: USER_INVITE_ROLES.creator },
  { label: USER_ROLE_DISPLAY_NAMES.designer, value: USER_INVITE_ROLES.designer },
  { label: USER_ROLE_DISPLAY_NAMES.viewer, value: USER_INVITE_ROLES.viewer },
];

// the same roles can be assigned to invite and changed for users, for now
export const USER_CHANGE_ROLES = USER_INVITE_ROLES;
export type USER_CHANGE_ROLES = (typeof USER_INVITE_ROLES)[keyof typeof USER_INVITE_ROLES];
