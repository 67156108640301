import { Observable } from 'rxjs';

export type ObjectPropsWithoutPadding = Omit<ObjectProps, 'padding'>;
export type ObjectPropsMap = Record<string, Partial<ObjectPropsWithoutPadding>>;

export interface ObjectProps {
  name?: string;
  backgroundColor: string;
  backgroundImageUrl?: string;
  backgroundImageOpacity?: number;
  backgroundImageSize?: string;
  blur?: number;
  borderColor: string;
  borderRadius: number;
  borderSize: number;
  borderType: ObjectBorderType;
  borderVisible: boolean;
  description: string;
  padding: number;
  title: string;
  subtitle: string;
  footer: string;
  titleVisible: boolean;
  subtitleVisible: boolean;
  footerVisible: boolean;
  paddingTop?: number;
  paddingBottom?: number;
  paddingLeft?: number;
  paddingRight?: number;
  positionTop: number;
  positionLeft: number;
  shadowBlurRadius: number;
  shadowColor: string;
  shadowOffsetX: number;
  shadowOffsetY: number;
  shadowVisible: boolean;
  width: number;
  height: number;
  marginTop: number;
  marginBottom: number;
  marginLeft: number;
  marginRight: number;
  visibility: boolean;
  isMaximizable: boolean;
  showExportToCsv: boolean;
  showExportToXlsx: boolean;
  masterLinkItemName: string;
}

export enum ObjectBorderType {
  solid = 'solid',
  dashed = 'dashed',
  dotted = 'dotted',
  double = 'double',
  none = 'none',
}

export interface IObjectPropertyAPI {
  setProperties(objectId: string, propsDiff: Partial<ObjectProps>): void;
  syncFrameProperties(objectId: string, propsDiff: Partial<ObjectProps>): void;
  getProperties(objectId: string): Observable<Partial<ObjectProps>>;
  getProperty<T extends keyof ObjectPropsWithoutPadding>(
    objectId: string,
    property: T
  ): Required<ObjectPropsWithoutPadding>[T];
  getCurrentProperties(): ObjectPropsMap;
  setActiveProperties(objectId: string, props: Partial<ObjectProps>): void;
  getActiveProperties(objectId: string): Observable<Partial<ObjectProps>>;
}
