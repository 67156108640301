import { EXTENSIONS, MIME_TYPES } from '@gucci/enums';

export const MIME_TYPE_GROUPS = {
  IMAGES: [MIME_TYPES.JPEG, MIME_TYPES.JPG, MIME_TYPES.PNG],
  DATA: [MIME_TYPES.CSV],
  ASTX: [MIME_TYPES.ASTX],
  DOCUMENTS: [MIME_TYPES.XLSX],
};

export const EXTENSION_TYPE_GROUPS = {
  IMAGES: [EXTENSIONS.JPEG, EXTENSIONS.JPG, EXTENSIONS.PNG],
  DATA: [EXTENSIONS.CSV],
  ASTX: [EXTENSIONS.ASTX],
  DOCUMENTS: [EXTENSIONS.XLSX],
};
