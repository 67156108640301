export enum InsightsTask {
  Summarize = 'Summarize',
  Trends = 'Trends',
  CompareTwoCharts = 'Compare Two Charts',
  PatternsAndOutliers = 'Patterns & outliers',
  SurpriseMe = 'Surprise me',
}

export enum InsightsTone {
  Formal = 'Formal',
  Informal = 'Informal',
}

export enum InsightsLanguage {
  English = 'en-gb',
  French = 'fr',
  German = 'de',
  Spanish = 'es',
  Portuguese = 'pt',
  Polish = 'pl',
  Swedish = 'sv',
  Ukrainian = 'uk',
  Hindi = 'hi',
}

export enum InsightsLength {
  Short = 'Short',
  Medium = 'Medium',
  Long = 'Long',
}

export enum ModelResponseFormat {
  JSON = 'json',
}

export enum ModelToolType {
  FUNCTION = 'function',
}

export * from './copilot';
