export enum NavigationArrowDirection {
  DOWN = 'down',
  RIGHT = 'right',
}

export enum SeparatorPosition {
  BEFORE,
  AFTER,
}

export enum NavigationElement {
  ITEM = 'item',
  LOGO = 'logo',
  PANEL = 'panel',
}
